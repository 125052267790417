import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { 
    id: String,
    isSelected: Boolean,
    newPage: Boolean,
    alreadySubmitted: Boolean
  }

  initialize() {
    this.selectElement = document.getElementById('buy-box-ids-input');
    this.submitBtnElement = document.getElementById('modal-btn');
  }

  selectBuyBox() {
    if (!this.newPageValue && this.alreadySubmittedValue) return super.renderToast("#buy-boxes-container", "You cannot deselect a Buy Box once submitted.")
    this.isSelectedValue = !this.isSelectedValue;
    this.element.firstElementChild.scroll(0, 0);
    this.selectElement.querySelector(`option[value="${this.idValue}"]`).selected = this.isSelectedValue;
    if (this.newPageValue) checkValidity(this.selectElement, this.submitBtnElement);
  }
}
export const checkValidity = (select, submitBtn) => {
  if (select.selectedOptions.length > 0) {
    submitBtn.removeAttribute("disabled");
    submitBtn.textContent = "Review";
    return;
  }
  submitBtn.setAttribute("disabled", "disabled");
  submitBtn.textContent = "Select Buy Boxes";
}
