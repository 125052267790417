import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  // ID is MOdals random generated ID in view component.
  // Selector is the class or ID passed in the open modal button or link that corresponds with the VC, so like 'data-modal-selector-value=".choose-language-modal"'
  static targets = ["modal", "modalCard", "modalBackground"];
  static values = {
    selector: String,
    exitAnimationClass: String,
    destroyOnExit: Boolean,
    listenForEsc: Boolean,
    url: String,
  };
  connect() {
    if (this.hasModalTarget) {
      if (this.modalTarget.classList.contains("is-active")) this.showModal();
    }
  }

  removeModalOpen() {
    document.body.removeAttribute("modal-open");
  }

  showModal(event) {
    // If element is a link, prevent default behavior
    if (this.element.tagName === "A") {
      event.preventDefault();
    }

    document.body.setAttribute("modal-open", true);
    this.modal = this.hasModalTarget
      ? this.modalTarget
      : document.getElementById(`modal-component-${this.selectorValue}`);

    // If modal needs content from a URL, set the URL value in frame
    if (this.hasUrlValue) this.setFrameUrl();

    this.modal.classList.add("is-active");

    this.focusModal();
    if (this.listenForEscValue) listenForEsc(this.modal);
  }

  setFrameUrl() {
    const frame = this.modal.querySelector("#remote-content-modal-frame");
    frame.setAttribute("src", this.urlValue);
  }

  focusModal() {
    super.focusFirstEl(this.modal);
  }

  closeModal() {
    closeModal(
      this.modal,
      this.modalCardTarget,
      this.modalBackgroundTarget,
      this.exitAnimationClassValue,
      this.destroyOnExitValue
    );
  }
}

export const listenForEsc = (modal = ".modal-component") => {
  window.addEventListener("keyup", function closeModalOnEsc(e, modal) {
    if (e.key === "Escape") {
      window.removeEventListener("keyup", closeModalOnEsc);
      closeModal(modal);
    }
  });
};

export const closeModal = (
  modal = ".modal-component",
  modalCard = ".modal-card",
  modalBackground = ".modal-background",
  modalExitAnimationClass = "animate-zoom-out",
  modalDestroyOnExit = false,
  timeoutMS = 490
) => {
  // Check if function call passed in a selector instead of an element (calling this function with a string or a specific nodeElement)
  if (typeof modal === "string")
    modal = document.querySelector(`${modal}.is-active`);
  if (typeof modalCard === "string")
    modalCard = document.querySelector(`.is-active ${modalCard}`);
  if (typeof modalBackground === "string")
    modalBackground = document.querySelector(`.is-active ${modalBackground}`);
  modalCard.classList.add(modalExitAnimationClass);
  modalBackground.classList.add("delay-fade-out");
  document.body.removeAttribute("modal-open");
  setTimeout(() => {
    modal.classList.remove("is-active");
    modalCard.classList.remove(modalExitAnimationClass);
    modalBackground.classList.remove("delay-fade-out");
    if (modalDestroyOnExit == true) {
      modal.remove();
    }
  }, timeoutMS);
};
