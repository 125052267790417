import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [
    "addressOneInput",
    "zipInput",
    "cityInput",
    "stateInput",
    "countyInput",
    "googleInput",
  ];

  connect() {
    super.connect();

    if (this.hasAddressOneInputTarget) {
      this.listenToGooglePlaceChangeEvent();

      this.listenToKeydownOnAddressOne();
    }
  }

  listenToGooglePlaceChangeEvent() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.addressOneInputTarget,
      {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      }
    );
    google.maps.event.addListener(
      this.autocomplete,
      "place_changed",
      this.onPlaceChanged.bind(this)
    );
  }

  listenToKeydownOnAddressOne() {
    this.addressOneInputTarget.addEventListener(
      "keydown",
      this.preventEnterSubmit
    );
  }

  preventEnterSubmit = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  onPlaceChanged() {
    const place = this.autocomplete.getPlace();
    const components = getAddressComponents(place);

    if (this.hasAddressOneInputTarget) {
      this.addressOneInputTarget.value = components.address_one;
    }
    this.zipInputTarget.value = components.zip_code;
    this.cityInputTarget.value = components.city;
    this.stateInputTarget.value = components.state;
    if (this.hasCountyInputTarget) {
      this.countyInputTarget.value = components.county;
    }
    this.googleInputTarget.value = components.full_address;
  }

  disconnect() {
    this.addressOneInputTarget.removeEventListener(
      "keydown",
      this.preventEnterSubmit
    );
  }
}

function getAddressComponents(place) {
  let addressComponents = {
    premise: "",
    street_number: "",
    route: "",
    postal_code: "",
    locality: "", // Used for city
    postal_town: "", // Some places might have city under postal_town
    administrative_area_level_1: "",
    country: "",
    administrative_area_level_2: "", // Used for county
    full_address: place.formatted_address || "",
  };

  place.address_components.forEach((component) => {
    component.types.forEach((type) => {
      if (addressComponents.hasOwnProperty(type)) {
        addressComponents[type] =
          type === "administrative_area_level_1" || type === "country"
            ? component.short_name
            : component.long_name;
      }
    });
  });

  // Determine address line one
  let addressOne = [
    addressComponents.premise,
    addressComponents.street_number,
    addressComponents.route,
  ]
    .filter((component) => component)
    .join(", ");

  // Special handling for city (locality) - using locality or postal_town
  let city = addressComponents.locality || addressComponents.postal_town;

  // Additional handling for special cases (e.g., New York)
  if (!city && addressComponents.administrative_area_level_1) {
    city = addressComponents.administrative_area_level_1;
  }

  return {
    address_one: addressOne,
    zip_code: addressComponents.postal_code,
    city: city,
    state: addressComponents.administrative_area_level_1,
    country_code: addressComponents.country,
    county: addressComponents.administrative_area_level_2,
    full_address: addressComponents.full_address,
  };
}
