import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  
  initialize(){
    this.stateInput = document.getElementById("property_state");
    this.countyInput = document.getElementById("property_county");
    this.addressInput =  document.getElementById("property_address_one");
    this.apnInput = document.getElementById("property_apn");
  }

  connect() {
    super.connect();

    if (this.addressInput) {
      const autocomplete = new google.maps.places.Autocomplete(this.addressInput, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });
      google.maps.event.addListener(
        autocomplete,
        "place_changed",
        this.onPlaceChanged,
      );
      
      // google.maps.event.addListener("place_changed", () => this.updateRegions);
      this.addressInput.addEventListener("keydown", (e) => {
        if (e.key === "Enter") e.preventDefault();
      });
      this.apnInput.addEventListener("focus", () => {
        this.updateRegions();
      });
    }
  }

  updateRegions() {
    if (this.stateInput.value) this.stimulate("RegionsReflex#update_property_regions", this.stateInput, this.countyInput.value);
  }
  
  onPlaceChanged() {
    const place = this.getPlace();
    const components = getAddressComponents(place);
    const addressInput = document.getElementById("property_address_one"),
      zipInput = document.getElementById("property_zip"),
      cityInput = document.getElementById("property_city"),
      countyInput = document.getElementById("property_county"),
      googleInput = document.getElementById("google-input"),
      stateInput = document.getElementById("property_state");
    [
      addressInput.value,
      zipInput.value,
      cityInput.value,
      stateInput.value,
      countyInput.value,
      googleInput.value,
    ] = [
      components.address_one,
      components.zip_code,
      components.city,
      components.state,
      components.county,
      components.full_address,
    ];
    document.getElementById("property_apn").focus();

  }
}

function getAddressComponents(place) {
  let street_number = "",
    route = "",
    zip_code = "",
    county = "",
    city = "",
    state = "",
    country_code = "",
    full_address = "";

  for (let i in place.address_components) {
    full_address = place.formatted_address;
    let component = place.address_components[i];
    for (let j in component.types) {
      let type = component.types[j];
      if (type === "street_number") {
        street_number = component.long_name;
      } else if (type === "route") {
        route = component.long_name;
      } else if (type === "postal_code") {
        zip_code = component.long_name;
      } else if (type === "locality") {
        city = component.long_name;
      } else if (type === "administrative_area_level_1") {
        state = component.short_name;
      } else if (type === "postal_town" && city === "") {
        city = component.long_name;
      } else if (type === "administrative_area_level_1" && city === "") {
        // NOTE: For some reasons addresses in New York doesn't return postal_town type nor locality
        city = component.long_name;
      } else if (type === "country") {
        country_code = component.short_name;
      } else if (type === "administrative_area_level_2") {
        county = component.long_name;
      }
    }
  }

  return {
    address_one: `${street_number} ${route}`,
    zip_code: zip_code,
    city: city,
    state: state,
    country_code: country_code,
    county: county,
    full_address: full_address,
  };


}
