import ApplicationController from "./application_controller";
import flatpickr from "flatpickr";

export default class extends ApplicationController {
  static targets = [ 
    "closeDateInput",
    "phoneNumberInput",
    "expirationDateInput",
    "earnestAmountInput",
    "offerAmountInput"
  ]
  static values = {
    earnestPercent: Number,
    earnestAmount: Number,
    hasDefault: Boolean
  }

  initialize() {
    new Cleave(this.phoneNumberInputTarget, {
      delimiter: '-',
      blocks: [3, 3, 4],
      swapHiddenInput: true,
      numerical: true,
      numericOnly: true,
    });    
    this.closeDate = flatpickr(this.closeDateInputTarget, {
      altInput: true, 
      altFormat: "F j, Y", 
      dateFormat: "Y-m-d",
      minDate: "today"
    });
    this.expirationDate = flatpickr(this.expirationDateInputTarget, {
      altInput: true, 
      altFormat: "F j, Y", 
      dateFormat: "Y-m-d",
      minDate: "today"
    });
  }

  connect() {
    super.connect();
    super.generateInputId('.offer_inspection_period')
    if (!this.hasDefaultValue && this.hasEarnestAmountValue) this.populateEarnestMoneyAmount(this.earnestAmountValue);
  }
  
  updateEarnestMoney(e) {
    if (this.hasDefaultValue || (!this.hasEarnestPercentValue && !this.hasEarnestAmountValue)) return; // Has set a default offer value, so break out
    if (this.hasEarnestPercentValue) this.calculateEarnestMoneyAmount();
  }
  
  calculateEarnestMoneyAmount() {
    const offerAmountInputVal = this.element.querySelector("#offer_amount").value;
    const percentValue = this.earnestPercentValue / 100;
    const earnestMoneyAmount = parseInt(offerAmountInputVal) * percentValue;
    const calculatedVal = Math.round(earnestMoneyAmount);
    this.populateEarnestMoneyAmount(calculatedVal);
  }
  
  populateEarnestMoneyAmount(calculatedValue) {
    this.earnestAmountInputTarget.setAttribute("disabled", "disabled");
    this.earnestAmountInputTarget.title = "This amount is based off your LOI";
    this.earnestAmountInputTarget.value = calculatedValue;
    this.element.querySelector("#offer_earnest_money").value = calculatedValue;
  }

  checkFormValidity(e) {
    e.preventDefault();

    let isValid = true;

    const fileUploadInput = document.getElementById("offer-documents-upload");
    if (this.checkInputFilesSize(fileUploadInput)) {
      fileUploadInput.parentElement.classList.remove("is-dropped")
      super.renderToast(
        "offer-documents-upload-container", "You've uploaded files that are too large and exceed 25MB. Please remove some files and try again."
      );
      isValid = false;
    }

    if (this.expirationDateInputTarget.value === "") {
      super.toggleInputError(this.expirationDateInputTarget, "Please select an Expiration Date")
      isValid = false;
    }

    if (isValid) return e.target.submit();
  }

  removeExpirationError(e) {
    super.removeInputError("toast-component-offer_expiration", e.target.parentElement)
  }


  checkInputFilesSize(fileUploadInput) {
    const files = fileUploadInput.files;

    if (files.length === 0) return false; // no files uploaded

    const maxFileSize = 25000000; // max file size 25MB in bytes
    let totalSize = 0;
  
    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size;
    }
    
    return totalSize > maxFileSize;
  }
}