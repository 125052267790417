import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "submitBtn", "stateSelect", "filterForm", "countySelect", "strategySelect" ]

  initialize() {
    this.stateIsSelected();
  }

  updateForm() {
    if (this.stateIsSelected()) this.submitFilter();  
  }

  stateIsSelected() {
    const messageAlertHeader = document.querySelector(".message-alert-header-container");
    const messageAlertBody = document.querySelector(".message-alert-body-container");
    if (this.stateSelectTarget.value == "") {
      this.submitBtnTarget.classList.add("disabled");
      this.submitBtnTarget.disabled = "disabled";
      if (this.hasCountySelectTarget) {
        this.countySelectTarget.classList.add("disabled");
        this.countySelectTarget.disabled = "disabled";
        this.strategySelectTarget.classList.add("disabled");
        this.strategySelectTarget.disabled = "disabled";
        messageAlertBody.textContent = "Please first select a State to begin searching for available Buy Boxes in your area.";
        messageAlertHeader.classList.remove("bg-gray-700");
        messageAlertHeader.classList.add("bg-danger");
      }
      return false;
    } else {  
      this.submitBtnTarget.classList.remove("disabled");
      this.submitBtnTarget.removeAttribute("disabled");
      if (this.hasCountySelectTarget) {
        this.countySelectTarget.classList.remove("disabled");
        this.countySelectTarget.removeAttribute("disabled");
        this.strategySelectTarget.classList.remove("disabled");
        this.strategySelectTarget.removeAttribute("disabled");
      }
      return true;
    }
  }

  submitFilter() {
    this.submitBtnTarget.value = "Filtering..."
    this.filterFormTarget.submit();
  }
}