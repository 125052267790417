import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
//? Is imported into components/design/controllers.js as well to gain access to helpers, so super.function() can be called.
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  addVanish(element) {
    element.classList.add("vanish");
  }

  removeVanish(element) {
    element.classList.remove("vanish");
  }

  toggleVanish(element) {
    element.classList.toggle("vanish");
  }

  //? Make sure to add a transition in the corresponding element.
  addFadeOut(element) {
    element.classList.add("animate-fade-out");
  }
  //? Make sure to add a transition in the corresponding element.
  removeFadeOut(element) {
    element.classList.remove("animate-fade-out");
  }
  //? Make sure to add a transition in the corresponding element.
  addFadeIn(element) {
    element.classList.add("animate-fade-in");
  }
  //? Make sure to add a transition in the corresponding element.
  removeFadeIn(element) {
    element.classList.remove("animate-fade-in");
  }
  //? Calls render_flash Reflex in application controller. 
  renderFlash(msg, name, morph_nothing) {
    this.stimulate("ApplicationReflex#render_flash", msg, name, morph_nothing)
  }
  //? Calls render_confirm_modal Reflex in application controller.
  renderConfirmModal(targetEl, 
                    url, 
                    name, 
                    message, 
                    title, 
                    type = "warning", 
                    confirmText = "Yes", 
                    headerText = "Are you sure?", 
                    cancelText = "Nevermind", 
                    isLink = true,
                    dataProps = {},
                    methodValue = "",
  ) {
    this.stimulate("ApplicationReflex#render_confirm_modal", targetEl, url, name, message, title, type, confirmText, headerText, cancelText, isLink, dataProps, methodValue);
  }
  //? Will toggle animation and vanish state depending on the function passed in as 'vanish' param. afterVanishFunction will be called once timeout finishes if anonymous function is passed in. CustomClasses can be passed in as an object, like: {inClass: "animate-bounce-in", outClass: "animate-bounce-out"}
  //? EG:    super.handleFadeAndVanish(this.inspectionInputTarget.parentElement, 900, this.waiveInspectionInputTarget.value == "true", () => this.element.remove(), {inClass: "animate-bounce-in", outClass: "animate-bounce-out"});
  handleFadeAndVanish(element, delay = 900, vanish = true, afterVanishFunction = null, customClassesObject = null) {
    if (vanish) {
      if (customClassesObject) {
        element.classList.remove(customClassesObject.inClass);
        element.classList.add(customClassesObject.outClass);
      } else {
        this.removeFadeIn(element);
        this.addFadeOut(element);
      }
      setTimeout(() => {
        this.toggleVanish(element);
        if (afterVanishFunction) afterVanishFunction();
      }, delay);
      return;
    }
    this.toggleVanish(element);
    if (customClassesObject) { 
      element.classList.remove(customClassesObject.outClass);
      element.classList.add(customClassesObject.inClass);
    } else {
      this.removeFadeOut(element);
      this.addFadeIn(element);
    }
  }

  // Since Cleave.JS has some ID generation issues and we render toasts off that, this method will generate an ID for the non-hidden input
  generateInputId(wrapperSelector, idName) {
    document.querySelector(`${wrapperSelector}`).firstElementChild.nextSibling.id = `${idName}`;
  }

  toggleInputError(input, errorMessage, showToast = true) {
    const wrapperElement = input.parentElement;
    input.addEventListener("click", () => {
      this.removeInputError(input.id, wrapperElement);
    });
    if (showToast) {
      this.renderToast(
        input.id, 
        `Please fix the highlighted input field <br> ${errorMessage}`
      )
    }
    wrapperElement.classList.add("has-errors");
  }

  removeInputError(id, inputParent) {
    this.dismissToast(id);
    inputParent.classList.remove('has-errors');
  }

  focusFirstEl(parent = "main") {
    const firstFocusable = parent.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')[0];
    firstFocusable?.focus();
  }
  
  // When using this, avoid using connect() in the controller you're calling from and use initialize() instead, since there's a conflict with this connect.
  // If you have to use connect() in the controller, first call super.connect().
  renderToast(selector, message, iconType = "warning") {
    if (this.alreadyHasToast(selector)) return;
    if (!selector.includes("#") && !selector.includes(".")) selector = `#${selector}`; // Add prefix for # if passed without it
    this.stimulate("ToastReflex#render_toast", selector, message, this.countToasts(), iconType);  
  }
  
  alreadyHasToast(id) {
    const toast = document.getElementById(`toast-component-${id}`);
    return toast;
  }

  // Counts toasts on page as margin to component so they stack
  countToasts() {
    return document.querySelectorAll(".toast-component").length;
  }

  dismissToast(id) {
    if (!id.includes('toast-component')) id = `toast-component-${id}`; // Will fix ID if what's passed doesn't have the 'toast-component' prefix.

    const toast = document.getElementById(id);

    if (toast) {
      toast.classList.add('dismiss');
      setTimeout(() => {
        toast.remove();
      }, 550);
    }
  }

  closeModal(modal = ".modal-component",
              modalCard = ".modal-card", 
              modalBackground = ".modal-background",
              modalExitAnimationClass = "animate-zoom-out",
              modalDestroyOnExit = false,
              timeoutMS = 490
  ) {
    // Check if function call passed in a selector instead of an element (calling this function with a string or a specific nodeElement)
    if (typeof(modal) === "string") modal = document.querySelector(`${modal}`) 
    if (typeof(modalCard) === "string") modalCard = modal.querySelector(`${modalCard}`)
    if (typeof(modalBackground) === "string") modalBackground = modal.querySelector(`${modalBackground}`) 
    
    modalCard.classList.add(modalExitAnimationClass);
    modalBackground.classList.add('delay-fade-out');
    setTimeout(() => {
      modal.classList.remove('is-active');
      modalCard.classList.remove(modalExitAnimationClass);
      modalBackground.classList.remove('delay-fade-out');
      document.body.removeAttribute("modal-open");
      if (modalDestroyOnExit == true) {
        modal.remove();
      }
    }, timeoutMS);
  }
}

