import { closeModal } from "../../components/design/modal/modal_controller";
import {checkMultiselectsValidity} from "../../components/design/forms/multiselect/multiselect_component_controller";

import ApplicationController from "./application_controller";
import { limitInputChar } from "../utilities/input_limiter";
export default class extends ApplicationController {
  checkSelectsValidity() {
    if (checkMultiselectsValidity()) super.renderToast(
          'buy-box-new-page',
          "Please select option(s) in the red highlighted form fields"
    );
  }
}
