import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["trigger", "toggleable", "secondToggleable"];
  static values = { toggleableShow: Array, secondToggleableShow: Array };

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.hasToggleableTarget) {
      const shouldShow = this._shouldShow();

      if (shouldShow) {
        this._show(this.toggleableTarget);
      } else {
        this._hide(this.toggleableTarget);
      }
    }
    if (this.hasSecondToggleableTarget) {
      const shouldShowSecond = this._shouldShowSecond();

      if (shouldShowSecond) {
        this._show(this.secondToggleableTarget);
      } else {
        this._hide(this.secondToggleableTarget);
      }
    }
  }

  _shouldShow() {
    // For checkboxes
    if (this.triggerTarget.type === "checkbox") {
      return this.triggerTarget.checked;
    }

    // For dropdowns
    return this.toggleableShowValue.includes(this.triggerTarget.value);
  }

  _shouldShowSecond() {
    return this.secondToggleableShowValue.includes(this.triggerTarget.value);
  }

  _show(element) {
    super.removeVanish(element);
  }

  _hide(element) {
    super.addVanish(element);
  }
}
